<!-- Remarks:
    - 只可選 報讀新課程 或 購買套票 或 購買商品，不可同時付款

    課程:
      - 報讀新課程 -> 付款 (new_order)
        -> 如果係regular course，backend會自動將未來嘅lesson assign比student
        -> create嘅個將order只係屬於user揀咗要比錢嘅lesson
      - 下次入嚟嘅時候會攞下個月order preview (extend_order_pre)，user可以remove唔要嘅lesson -> 付款 (extend_order)
      - 同時都會攞 status !== 'paid' 嘅order比user update返個status，但唔可以add/remove啲lesson -> 付款 (edit_order)

    套票: 
      - 買新套票 -> 揀一堂regular course -> 自動grab之後所有堂 
        [-> 如果唔夠堂，問要唔要新增lesson
        -> 如果有剩嘅package lesson，問要唔要assign埋呢到]
        -> 付款 (new_order)
      - 同時都會攞 status !== 'paid' 嘅order比user update返個status，但唔可以edit -> 付款 (edit_order)
    
    商品: 
      - 買新商品 -> 付款 (new_order)
      - 同時都會攞 status !== 'paid' 嘅order比user update返個status，但唔可以edit items -> 付款 (edit_order)
 -->

<template>
  <v-container fluid>
    <PageHeaderSlot showBackButton> </PageHeaderSlot>
    <v-row class="ma-0">
      <v-col class="pl-0 pr-0 pr-md-8" cols="12" md="9">
        <v-tabs
          class="mb-4"
          :class="{ 'pointer-none': $store.getters.isLoading }"
          v-model="paymentTypeTab"
          color="colorBlack"
          :height="40"
          active-class="tab-active"
          hide-slider
          @change="onTabChange()"
        >
          <v-tab :disabled="$store.getters.isLoading">{{ $t('applyNewCourse') }}</v-tab>
          <v-tab :disabled="$store.getters.isLoading">{{ $t('purchasePackage') }}</v-tab>
          <v-tab :disabled="$store.getters.isLoading">{{ $t('purchaseProduct') }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="paymentTypeTab" class="mt-8">
          <!-- Course -->
          <v-tab-item transition="fade-transition">
            <v-row class="ma-0 mb-5">
              <v-btn class="primary" text @click.prevent="openAddSearchDialog()">{{ $t('applyNewCourse') }}</v-btn>
            </v-row>

            <Datatable
              :isLoading="tableLoading"
              tableName="course"
              :tableHeaders="courseTableHeaders"
              :tableData="courseTableData"
              disableFooter
              disablePagination
              enableSelectToggle
              :selectedTableRow.sync="courseSelectedRow"
              tableEmptyText="noCourse"
              @edit-clicked="handleEditOpen($event, 'course')"
              @delete-clicked="handleDeleteAction($event)"
              @selected-row-changed="onCourseSelectedRowChanged()"
            ></Datatable>
          </v-tab-item>

          <!-- Package -->
          <v-tab-item transition="fade-transition">
            <v-row class="ma-0 mb-5">
              <v-btn class="primary" text @click.prevent="openAddSearchDialog()">{{ $t('choosePackage') }}</v-btn>
            </v-row>

            <Datatable
              :isLoading="tableLoading"
              tableName="package"
              :tableHeaders="packageTableHeaders"
              :tableData="packageTableData"
              disableFooter
              disablePagination
              enableSelectToggle
              :selectedTableRow.sync="packageSelectedRow"
              tableEmptyText="noPackage"
              @edit-clicked="handleEditOpen($event, 'package')"
              @delete-clicked="handleDeleteAction($event)"
              @selected-row-changed="onPackageSelectedRowChanged()"
            ></Datatable>
          </v-tab-item>

          <!-- Product -->
          <v-tab-item transition="fade-transition">
            <v-row class="ma-0 mb-5">
              <v-btn class="primary" text @click.prevent="openAddSearchDialog()">{{ $t('addProduct') }}</v-btn>
            </v-row>

            <Datatable
              :isLoading="tableLoading"
              tableName="product"
              :tableHeaders="productTableHeaders"
              :tableData="productTableData"
              disableFooter
              disablePagination
              enableSelectToggle
              :selectedTableRow.sync="productSelectedRow"
              tableEmptyText="noProduct"
              @edit-clicked="handleEditOpen($event, 'product')"
              @delete-clicked="handleDeleteAction($event)"
              @selected-row-changed="onProductSelectedRowChanged()"
            ></Datatable>
          </v-tab-item>
        </v-tabs-items>
      </v-col>

      <!-- 訂單詳情 -->
      <v-col class="px-0 my-4" cols="12" md="3">
        <v-card>
          <v-card-title class="subtitle-2 order-card-title">{{ $t('orderDetails') }}</v-card-title>
          <v-card-text class="px-0 py-4">
            <v-row class="ma-0 pb-3 px-4 details-row">
              <span>{{ $t('subtotal') }}</span>
              <span>${{ $formatter.separator($formatter.formatMoney(subtotal)) }}</span>
            </v-row>
            <v-row class="ma-0 pb-3 px-4 details-row">
              <span>{{
                $t(
                  currentTabType === 'product'
                    ? 'productQty'
                    : currentTabType === 'package'
                    ? 'packageQty'
                    : 'lessonQty',
                )
              }}</span>
              <span>{{ qty }}</span>
            </v-row>
            <v-divider class="my-4"></v-divider>
            <v-row class="ma-0 pb-3 px-4 details-row">
              <span>{{ $t('payTotal') }}</span>
              <span style="font-size: 40px;">${{ $formatter.separator(totalAmount) }}</span>
            </v-row>
            <v-row class="ma-0 pb-3 px-4">
              <v-col class="pa-0 ma-0" cols="12" v-if="!isUpdatePayment">
                <FormSelect
                  label="discountType"
                  :fieldValue.sync="orderDiscountType"
                  :options="discountTypeOptions"
                  placeholder="selectDiscountType"
                  dense
                  @changed="orderDiscountValue = ''"
                  enabledClear
                />
              </v-col>
              <v-col class="pa-0 ma-0" cols="12" v-if="!isUpdatePayment && $validate.DataValid(orderDiscountType)">
                <FormInput
                  type="number"
                  :label="orderDiscountLabel"
                  :fieldValue.sync="orderDiscountValue"
                  dense
                  :readonly="!$validate.DataValid(orderDiscountType)"
                  :prefix="orderDiscountType === 'fix_amount' ? '$' : ''"
                  :suffix="orderDiscountType === 'percentage' ? '%' : ''"
                />
              </v-col>
              <v-col class="pa-0 ma-0" cols="12">
                <FormSelect
                  label="paymentSupport"
                  :fieldValue.sync="selectedPaymentMethod"
                  :options="paymentMethodOptions"
                  required
                  dense
                />
              </v-col>
              <v-col class="pa-0 ma-0" cols="12">
                <FormSelect
                  label="payStatus"
                  :fieldValue.sync="selectedStatus"
                  :options="statusOptions"
                  required
                  dense
                />
              </v-col>
              <v-col class="pa-0 mb-4" cols="12">
                <FormFileUploader
                  uploadText="uploadPaymentRecord"
                  fileInputKey="upload_payment"
                  :files.sync="paymentImages"
                  acceptFormat="image"
                  dispatchUpdateOnChange
                  single
                />
              </v-col>
              <v-col class="pa-0 ma-0" cols="12">
                <FormInput label="remark" placeholder="addRemark" :fieldValue.sync="remark" dense />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-btn
          :loading="$store.getters.isLoading"
          class="accent my-3 colorBlack--text"
          text
          width="100%"
          height="48px"
          min-height="48px"
          @click.prevent="handlePaymentSubmit()"
          >{{ $t(isUpdatePayment ? 'updateOrder' : 'pay') }}</v-btn
        >

        <v-card>
          <v-card-text class="pa-4">
            <v-row class="ma-0 pb-6 details-row">
              <div>
                <div class="mb-2">{{ $t('studentName') }}</div>
                <div class="font-weight-regular">{{ studentData.student_name }}</div>
              </div>
              <v-avatar size="40" v-if="studentAvatar !== ''">
                <img :src="studentAvatar" :alt="studentData.student_name" />
              </v-avatar>
            </v-row>

            <v-row class="ma-0 pb-6 details-row">
              <div>
                <div class="mb-2">{{ $t('studentPhone') }}</div>
                <div class="font-weight-regular">{{ studentData.phone }}</div>
              </div>
              <v-btn class="whatsapp" text :href="`https://wa.me/${studentData.phone}`" target="_blank">
                <v-icon size="24" color="white">{{ icon.mdiWhatsapp }}</v-icon>
              </v-btn>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-space-between">
            <v-btn
              class="primary"
              :class="{ unflex: $i18n.locale === 'en' }"
              text
              width="48%"
              :to="{ name: 'StudentDetails', params: { id: studentData.student_id } }"
              >{{ $t('viewStudentInfo') }}</v-btn
            >
            <v-btn class="primary" text width="48%" :to="{ name: 'OrderList', params: { sid: id } }">{{
              $t('recentInvoice')
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- 報讀新課程 Dialog -->
    <DialogLayout
      :openDialog="addCourseDialogOpen"
      :maxWidth="910"
      title="applyNewCourse"
      hideAction
      enableScroll
      cardContentClass="pa-0"
      @close="addCourseDialogOpen = false"
    >
      <div class="pa-4">
        <v-radio-group
          name="addCourseType"
          v-model="addCourseFilter.type"
          row
          hide-details
          class="ma-0"
          @change="onCourseTypeChange()"
          :readonly="isSearching"
        >
          <v-radio :label="$t('applyCourse')" value="weekday"></v-radio>
          <v-radio
            :label="$t('applySingleLesson')"
            value="singleDate"
            v-if="this.currentTabType !== 'package'"
          ></v-radio>
        </v-radio-group>

        <v-row class="ma-0 my-4">
          <v-col cols="12" sm="6">
            <Searchbar
              label="searchCourse"
              :searchText.sync="addCourseFilter.keyword"
              @search-clicked="onCourseFilterChange()"
              @search-clear="onCourseFilterChange()"
              :isSearching="isSearching"
              :searchType.sync="addCourseFilter.keywordType"
              :searchTypeOptions="searchTypeOptions"
              enableMultiTypeSearch
              enabledClear
            />
          </v-col>

          <v-col cols="12" sm="6" v-if="addCourseFilter.type === 'weekday'">
            <FormSelect
              label="weekday"
              :fieldValue.sync="addCourseFilter.weekdays"
              :options="weekdayOptions"
              hideDetails
              :readonly="isSearching"
              placeholder="selectWeekday"
              isMultiple
              dense
              @changed="onCourseFilterChange()"
            />
          </v-col>

          <v-col cols="12" sm="6" v-if="addCourseFilter.type === 'singleDate'">
            <FormDatePicker
              label="lessonDate"
              :dateValue.sync="addCourseFilter.dateRange"
              isDateRange
              @changed="onCourseFilterChange()"
              hideDetails
              placeholder="selectLessonDate"
              dense
            />
          </v-col>

          <v-col cols="12" sm="6">
            <FormSelect
              label="tutor"
              :fieldValue.sync="addCourseFilter.tutor"
              :options="tutorOptions"
              hideDetails
              :readonly="isSearching"
              placeholder="selectTutor"
              dense
              @changed="onCourseFilterChange()"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <FormSelect
              label="grade"
              :fieldValue.sync="addCourseFilter.grade"
              :options="$gradeOptions"
              hideDetails
              :readonly="isSearching"
              placeholder="selectGrade"
              dense
              @changed="onCourseFilterChange()"
            />
          </v-col>
        </v-row>
      </div>

      <Datatable
        :isLoading="isSearching"
        :tableData="searchTableData"
        :tableHeaders="addCourseTableHeaders"
        :itemTotal="searchTableTotal"
        :page="searchTableOptions.tablePage"
        :pageLimit="searchTableOptions.tableLimit"
        :pageLimitOptions="searchTableOptions.limitOptions"
        @options-update="onSearchTableOptionsChange($event)"
        tableEmptyText="noCourse"
      >
        <template v-slot:[`item.actionSelectCourse`]="{ item }">
          <div class="colorGrey--text pointer-none" v-if="item.applied === true">{{ $t('lessonStatus.applied') }}</div>
          <div class="colorGrey--text pointer-none" v-else-if="currentTabType === 'course' && item.selected === true">
            {{ $t('lessonStatus.selected') }}
          </div>
          <v-btn
            v-else-if="currentTabType === 'package' && item.selected === true"
            :loading="$store.getters.isLoading"
            depressed
            text
            color="red"
            :ripple="false"
            class="pa-0"
            :class="{ 'pointer-none': $store.getters.isLoading }"
            style="min-width: unset !important;"
            @click="handlePackageLessonSelected(item.id)"
            >{{ $t('cancel') }}</v-btn
          >
          <v-btn
            v-else
            :loading="$store.getters.isLoading"
            depressed
            text
            color="primary"
            :ripple="false"
            class="pa-0"
            :class="{ 'pointer-none': $store.getters.isLoading }"
            style="min-width: unset !important;"
            @click="handleAddNewCourse(item.id)"
            :disabled="item.disabled"
            >{{ $t('apply') }}</v-btn
          >
        </template>
      </Datatable>

      <div class="pa-4" v-if="currentTabType === 'package'">
        <v-btn class="primary" text @click.prevent="handlePackageLessonConfirm()">{{
          $t('confirm')
        }}</v-btn>
      </div>
    </DialogLayout>

    <!-- 新增產品 Dialog -->
    <DialogLayout
      :openDialog="addProductDialogOpen"
      :maxWidth="910"
      title="addProduct"
      hideAction
      enableScroll
      cardContentClass="pa-0"
      @close="addProductDialogOpen = false"
    >
      <div class="pa-4">
        <v-row class="ma-0 my-4">
          <v-col cols="12" sm="6">
            <Searchbar
              label="searchProduct"
              :searchText.sync="addProductKeyword"
              searchPlaceholder="searchProductName"
              @search-clicked="onProductFilterChange()"
              @search-clear="onProductFilterChange()"
              :isSearching="isSearching"
              enabledClear
            />
          </v-col>
        </v-row>
      </div>

      <Datatable
        :isLoading="isSearching"
        :tableData="searchTableData"
        :tableHeaders="addProductTableHeaders"
        :itemTotal="searchTableTotal"
        :page="searchTableOptions.tablePage"
        :pageLimit="searchTableOptions.tableLimit"
        :pageLimitOptions="searchTableOptions.limitOptions"
        @options-update="onSearchTableOptionsChange($event)"
        tableEmptyText="noProduct"
      >
        <template v-slot:[`item.actionSelectProduct`]="{ item }">
          <v-btn
            :loading="$store.getters.isLoading"
            :disabled="$validate.DataValid(item.inventory) && item.inventory < 1"
            depressed
            text
            color="primary"
            :ripple="false"
            class="pa-0"
            :class="{ 'pointer-none': $store.getters.isLoading }"
            style="min-width: unset !important;"
            @click="handleAddNewProduct(item.id)"
            >{{ $t('select') }}</v-btn
          >
        </template>
      </Datatable>
    </DialogLayout>

    <!-- 選擇套票 Dialog -->
    <DialogLayout
      :openDialog="addPackageDialogOpen"
      :maxWidth="910"
      title="choosePackage"
      hideAction
      enableScroll
      cardContentClass="pa-0"
      @close="addPackageDialogOpen = false"
    >
      <div class="pa-4">
        <v-row class="ma-0 my-4">
          <v-col cols="12" sm="6">
            <Searchbar
              label="searchPackage"
              :searchText.sync="addPackageKeyword"
              searchPlaceholder="searchPackageName"
              @search-clicked="onPackageFilterChange()"
              @search-clear="onPackageFilterChange()"
              :isSearching="isSearching"
              enabledClear
            />
          </v-col>
        </v-row>
      </div>

      <Datatable
        :isLoading="isSearching"
        :tableData="searchTableData"
        :tableHeaders="addPackageTableHeaders"
        :itemTotal="searchTableTotal"
        :page="searchTableOptions.tablePage"
        :pageLimit="searchTableOptions.tableLimit"
        :pageLimitOptions="searchTableOptions.limitOptions"
        @options-update="onSearchTableOptionsChange($event)"
        tableEmptyText="noPackage"
      >
        <template v-slot:[`item.actionSelectPackage`]="{ item }">
          <v-btn
            :loading="$store.getters.isLoading"
            depressed
            text
            color="primary"
            :ripple="false"
            class="pa-0"
            :class="{ 'pointer-none': $store.getters.isLoading }"
            style="min-width: unset !important;"
            @click="handleAddNewPackage(item.id)"
            >{{ $t('selectAndApply') }}</v-btn
          >
        </template>
      </Datatable>
    </DialogLayout>

    <!-- 修改項目資料 Dialog -->
    <FormDialog
      ref="editFormDialog"
      formRef="editForm"
      title="editItem"
      :maxWidth="910"
      cardContentClass="pa-0"
      @submit-clicked="handleEditSubmit()"
    >
      <v-row class="ma-0 my-4">
        <v-col cols="12" sm="6" v-if="currentTabType !== 'package'">
          <FormSelect
            label="discountType"
            :fieldValue.sync="editDiscountType"
            :options="discountTypeOptions"
            placeholder="selectDiscountType"
            dense
            @changed="editDiscountValue = ''"
            enabledClear
          />
        </v-col>

        <v-col cols="12" sm="6" v-if="currentTabType !== 'package'">
          <FormInput
            type="number"
            :label="editDiscountLabel"
            :fieldValue.sync="editDiscountValue"
            dense
            :readonly="!$validate.DataValid(editDiscountType)"
            :prefix="editDiscountType === 'fix_amount' ? '$' : ''"
            :suffix="editDiscountType === 'percentage' ? '%' : ''"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <div class="input-label">{{ $t('price') }}</div>
          <div class="d-flex align-center mt-3">
            <span
              :class="{ 'text-decoration-line-through': $validate.DataValid(editDiscountPrice) }"
              class="colorBlack--text"
              >${{ $formatter.separator(editOriginalPrice || 0) }}</span
            >
            <span v-if="$validate.DataValid(editDiscountPrice)" class="primary--text ml-2"
              >${{ $formatter.separator(editDiscountPrice) }}</span
            >
          </div>
        </v-col>
      </v-row>

      <Datatable
        v-if="currentTabType === 'course'"
        :isLoading="editTableLoading"
        :tableData="editTableData"
        :tableHeaders="editCourseTableHeaders"
        disableFooter
        disablePagination
        enableSelectToggle
        :selectedTableRow.sync="editCourseSelectedRow"
        tableEmptyText="calendar.noLesson"
      ></Datatable>
      <Datatable
        v-else-if="currentTabType === 'product'"
        :isLoading="editTableLoading"
        :tableData="editTableData"
        :tableHeaders="editProductTableHeaders"
        tableEmptyText="noProduct"
        disableFooter
        disablePagination
      ></Datatable>
      <Datatable
        v-else-if="currentTabType === 'package'"
        :isLoading="editTableLoading"
        :tableData="editTableData"
        :tableHeaders="editPackageTableHeaders"
        disableFooter
        disablePagination
        tableEmptyText="calendar.noLesson"
      ></Datatable>
    </FormDialog>

    <!-- 新增其他課堂 Dialog -->
    <FormDialog
      title="askConfirmToAddLesson"
      :maxWidth="680"
      ref="addLessonFormDialog"
      formRef="addLessonForm"
      @submit-clicked="handleAddMoreLesson(true)"
      @close="handleAddMoreLesson(false)"
      enableCancel
      confirmBtnText="confirmToAddLesson"
      cancelBtnText="continueToApply"
    >
      <v-row class="ma-0">
        <v-col class="py-0" cols="12" sm="6">
          <FormInput
            label="addLessonCount"
            :fieldValue.sync="createLessonCount"
            type="number"
            required
            isIntegerOnly
            dense
          />
        </v-col>
      </v-row>
    </FormDialog>

    <!-- 選擇「未付款」課堂 Dialog -->
    <FormDialog
      ref="unpaidLessonFormDialog"
      formRef="unpaidLessonForm"
      title="chooseUnpaidLesson"
      :maxWidth="910"
      cardContentClass="pa-0"
      @submit-clicked="handleUnpaidLessonSelected(true)"
      @close="handleUnpaidLessonSelected(false)"
      enableCancel
      cancelBtnText="confirmUnpaidChoiceAndContinue"
      disableCloseHide
    >
      <Datatable
        :tableData="unpaidLessonTableData"
        :tableHeaders="editPackageTableHeaders"
        disableFooter
        disablePagination
        enableSelectToggle
        :selectedTableRow.sync="unpaidLessonSelectedRow"
        tableEmptyText="calendar.noLesson"
      ></Datatable>
    </FormDialog>

    <ConfirmDialog ref="deleteDialog" enableShowRemindCheckbox />
    <ConfirmDialog
      ref="exceedStudentDialog"
      title="message.confirmToApplyCourse"
      :isDeleteDialog="false"
      enableShowRemindCheckbox
      >{{ $t('message.reachedUpperLimitConfirmApply') }}</ConfirmDialog
    >
    <ConfirmDialog
      ref="combineRemainDialog"
      title="message.restPackageApplyCourse"
      :isDeleteDialog="false"
      :confirmBtnText="$t('allApplyToCourse', { count: allApplyCount })"
      :cancelBtnText="
        $t('continueToApplyCourse', { count: tempSelectedPackage ? tempSelectedPackage.lesson_count : 0 })
      "
    />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { mdiWhatsapp } from '@mdi/js'
import Datatable from '@/components/Datatable.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import FormSelect from '@/components/formField/FormSelect.vue'
import FormInput from '@/components/formField/FormInput.vue'
import FormDatePicker from '@/components/formField/FormDatePicker.vue'
import DialogLayout from '@/components/layout/DialogLayout.vue'
import FormDialog from '@/components/FormDialog.vue'
import Searchbar from '@/components/Searchbar.vue'
import FormFileUploader from '@/components/formField/FormFileUploader.vue'

export default {
  name: 'PayTuitionPayment',
  components: {
    Datatable,
    ConfirmDialog,
    FormSelect,
    FormInput,
    FormDatePicker,
    DialogLayout,
    FormDialog,
    Searchbar,
    FormFileUploader,
  },
  computed: {
    id() {
      const id = parseInt(this.$route.params.id)
      if (!isNaN(id)) {
        return id
      }

      return null
    },
    currentTabType() {
      return this.tabValues[this.paymentTypeTab]
    },
    courseTableHeaders() {
      const headers = [
        { value: 'course_name', text: this.$t('purchaseItem'), width: 150 },
        { value: 'month', text: this.$t('month'), hasYear: true },
        { value: 'dates', text: this.$t('date'), width: 300 },
      ]
      if (this.$validate.DataValid(this.courseTableData)) {
        return [
          ...headers,
          { value: 'unit_price', text: this.$t('price') },
          { value: 'qty', text: this.$t('quantity') },
          { value: 'total', text: this.$t('subtotal') },
          { value: 'paymentAction', text: '', align: 'end' },
        ]
      }

      return [
        ...headers,
        { value: 'unit_price', text: '' },
        { value: 'qty', text: '' },
        { value: 'total', text: '' },
        { value: 'paymentAction', text: '', align: 'end' },
      ]
    },
    productTableHeaders() {
      if (this.$validate.DataValid(this.productTableData)) {
        return [
          { value: 'product_name', text: this.$t('purchaseItem'), width: 150 },
          { value: 'unit_price', text: this.$t('price') },
          { value: 'qty', text: this.$t('quantity') },
          { value: 'total', text: this.$t('subtotal') },
          { value: 'paymentAction', text: '', align: 'end' },
        ]
      }

      return [{ value: 'product_name', text: this.$t('purchaseItem') }]
    },
    packageTableHeaders() {
      if (this.$validate.DataValid(this.packageTableData)) {
        return [
          { value: 'package_name', text: this.$t('purchaseItem'), width: 150 },
          { value: 'lesson_count', text: this.$t('lessonQty') },
          { value: 'dates', text: this.$t('date'), width: 300 },
          { value: 'unit_price', text: this.$t('price') },
          { value: 'total', text: this.$t('subtotal') },
          { value: 'paymentAction', text: '', align: 'end' },
        ]
      }

      return [{ value: 'package_name', text: this.$t('purchaseItem') }]
    },
    studentAvatar() {
      if (this.studentData.gender === 'M') {
        return require('@/assets/images/gender-m.svg')
      } else if (this.studentData.gender === 'F') {
        return require('@/assets/images/gender-f.svg')
      } else {
        return ''
      }
    },
    addCourseTableHeaders() {
      return [
        { value: 'course_name', text: this.$t('purchaseItem') },
        { value: 'full_date', text: this.$t('date'), hasWeekday: this.addCourseFilter.type === 'weekday' },
        { value: 'timeslot', text: this.$t('time') },
        { value: 'grade', text: this.$t('grade') },
        { value: 'tutor', text: this.$t('tutor') },
        { value: 'current_per_max_student_num', text: this.$t('personCount') },
        { value: 'actionSelectCourse', text: '', align: 'end' },
      ]
    },
    editDiscountLabel() {
      return this.getDiscountLabel(this.editOriginalPrice, this.editDiscountType, this.editDiscountValue)
    },
    editDiscountPrice() {
      const p = this.getDiscountPrice(this.editOriginalPrice, this.editDiscountType, this.editDiscountValue)
      if (p !== null) {
        return p.toString()
      }

      return ''
    },
    orderDiscountLabel() {
      return this.getDiscountLabel(this.subtotal, this.orderDiscountType, this.orderDiscountValue)
    },
    totalAmount() {
      let total = this.subtotal
      if (!this.isUpdatePayment && this.orderDiscountType && this.orderDiscountValue) {
        total = this.getDiscountPrice(this.subtotal, this.orderDiscountType, this.orderDiscountValue)
      }

      if (!total) {
        total = 0
      }

      return this.$formatter.formatMoney(total)
    },
  },
  watch: {
    courseSelectedRow: {
      handler(val) {
        this.calPrice()
      },
      deep: true,
      immediate: true,
    },
    courseTableData: {
      handler(val) {
        this.calPrice()
      },
      deep: true,
      immediate: true,
    },
    productSelectedRow: {
      handler(val) {
        this.calPrice()
      },
      deep: true,
      immediate: true,
    },
    productTableData: {
      handler(val) {
        this.calPrice()
      },
      deep: true,
      immediate: true,
    },
    packageSelectedRow: {
      handler(val) {
        this.calPrice()
      },
      deep: true,
      immediate: true,
    },
    packageTableData: {
      handler(val) {
        this.calPrice()
      },
      deep: true,
      immediate: true,
    },
  },
  data: vm => ({
    rowId: 0,
    tableLoading: false,
    tutorOptions: [],
    paymentTypeTab: 0,
    subtotal: 0,
    qty: 0,

    studentData: {
      student_id: -1,
      student_name: '',
      gender: '',
      phone: '',
      school_id: -1,
      grade: '',
    },
    remark: '',
    selectedPaymentMethod: 'cash',
    paymentImages: [],
    selectedStatus: 'paid',
    orderDiscountType: '',
    orderDiscountValue: '',
    isUpdatePayment: false,
    studentCourseOrders: [],

    searchTableOptions: {
      limitOptions: [5, 10, 20],
      tableLimit: 5,
      tablePage: 1,
    },
    searchTableTotal: 0,
    searchTableData: [],
    isSearching: false,
    searchTableSelectedRow: [],

    // edit common
    editTableLoading: false,
    editTableData: [],
    editDiscountType: '', // 'percentage' | 'fix_amount'
    editDiscountValue: '',
    editOriginalPrice: '',
    // edit course
    editCourseSelectedRow: [],

    // ===== Course related
    courseTableData: [],
    courseSelectedRow: [],
    nextPreviewOrder: null,

    // search course
    addCourseFilter: {
      type: 'weekday', // 'weekday' | 'singleDate'
      keyword: '',
      keywordType: 'course_name',
      weekdays: [],
      dateRange: [],
      tutor: '', // tutor id
      grade: '',
    },
    addCourseDialogOpen: false,

    // ===== Product related
    productTableData: [],
    productSelectedRow: [],
    // search product
    addProductDialogOpen: false,
    addProductKeyword: '',

    // ===== Package related
    packageTableData: [],
    packageSelectedRow: [],
    remainPackageLessonCount: 0,
    remainPackageLessonCountInit: true,
    createLessonCount: 0,
    allApplyCount: 0,

    // search package
    addPackageDialogOpen: false,
    addPackageKeyword: '',
    tempSelectedPackage: null,

    // unpaid lesson
    unpaidLessonTableData: [],
    unpaidLessonSelectedRow: [],

    // ===== static data
    tabValues: ['course', 'package', 'product'],
    icon: {
      mdiWhatsapp,
    },
    paymentMethodOptions: [
      { value: 'cash', text: vm.$t('paymentMethod.cash') },
      { value: 'fps', text: vm.$t('paymentMethod.fps') },
      { value: 'bank_transfer', text: vm.$t('paymentMethod.bank_transfer') },
      { value: 'payme', text: vm.$t('paymentMethod.payme') },
      { value: 'visa', text: vm.$t('paymentMethod.visa') },
      { value: 'master', text: vm.$t('paymentMethod.master') },
      { value: 'wechat_pay', text: vm.$t('paymentMethod.wechat_pay') },
      { value: 'alipay', text: vm.$t('paymentMethod.alipay') },
      { value: 'unionpay', text: vm.$t('paymentMethod.unionpay') },
    ],
    weekdayOptions: [
      { value: '0', text: vm.$t('weekdaysShort[0]') },
      { value: '1', text: vm.$t('weekdaysShort[1]') },
      { value: '2', text: vm.$t('weekdaysShort[2]') },
      { value: '3', text: vm.$t('weekdaysShort[3]') },
      { value: '4', text: vm.$t('weekdaysShort[4]') },
      { value: '5', text: vm.$t('weekdaysShort[5]') },
      { value: '6', text: vm.$t('weekdaysShort[6]') },
    ],
    discountTypeOptions: [
      { value: 'percentage', text: vm.$t('percentage') },
      { value: 'fix_amount', text: vm.$t('fixAmount') },
    ],
    editCourseTableHeaders: [
      { value: 'full_date', text: vm.$t('date'), width: 145 }, // need 'date'
      { value: 'weekday', text: vm.$t('weekday'), width: 145 },
      { value: 'timeslot', text: vm.$t('time') },
    ],
    addProductTableHeaders: [
      { value: 'product_name', text: vm.$t('purchaseItem'), width: 400 },
      { value: 'inventory', text: vm.$t('inventory') },
      { value: 'unit_price', text: vm.$t('price') },
      { value: 'qtySetter', text: vm.$t('quantity'), align: 'center', width: 140 },
      { value: 'actionSelectProduct', text: '', align: 'end' },
    ],
    editProductTableHeaders: [
      { value: 'product_name', text: vm.$t('purchaseItem'), width: 400 },
      { value: 'inventory', text: vm.$t('inventory') },
      { value: 'qtySetter', text: vm.$t('quantity'), align: 'center', width: 140 },
    ],
    addPackageTableHeaders: [
      { value: 'package_name', text: vm.$t('purchaseItem'), width: 150 },
      { value: 'lesson_count', text: vm.$t('lessonQty') },
      { value: 'unit_price', text: vm.$t('price') },
      { value: 'total', text: vm.$t('subtotal') },
      { value: 'actionSelectPackage', text: '', align: 'end' },
    ],
    editPackageTableHeaders: [
      { value: 'course_name', text: vm.$t('courseName'), width: 150 },
      { value: 'full_date', text: vm.$t('date'), width: 145 }, // need 'date'
      { value: 'weekday', text: vm.$t('weekday'), width: 145 },
      { value: 'timeslot', text: vm.$t('date') },
    ],
    searchTypeOptions: [
      { value: 'course_name', text: vm.$t('courseName') },
      { value: 'course_code', text: vm.$t('courseCode') },
    ],
    statusOptions: [
      { value: 'paid', text: vm.$t('paymentStatus.paid') },
      { value: 'pending', text: vm.$t('paymentStatus.pending') },
      { value: 'processing', text: vm.$t('paymentStatus.processing') },
    ],
    // ===== End of static data
  }),
  methods: {
    ...mapActions(['setDialogMessage', 'setShowDialog']),
    async getPayTuitionData() {
      try {
        const data = await this.$Fetcher.GetPayTuitionById(this.id)
        for (const key in this.studentData) {
          if (this.$validate.DataValid(data[key])) {
            this.studentData[key] = data[key]
          }
        }

        await this.generateNextMonthPreview()
      } catch (err) {
        this.$common.error(err)
        this.setDialogMessage({
          message: 'noData',
          returnLink: { name: 'PayTuitionList' },
        })
        this.setShowDialog(true)
        this.$store.dispatch('toggleLoadingPage', false)
      }
    },
    async generateNextMonthPreview() {
      // 攞下個月order preview
      try {
        const previewOrder = await this.$Fetcher.GenNextMonthPreviewOrder(this.studentData.student_id)
        this.nextPreviewOrder = previewOrder
        const courseRows = []
        previewOrder.item_list.forEach(item => {
          const targetItem = previewOrder.item_datas.find(el => el.id === item.id)
          if (targetItem) {
            const lessonItem = {
              ...targetItem,
              unit_price: targetItem.price,
              order_id: -2,
            }

            const found = courseRows.find(el => el[0].regular_id === targetItem.regular_id)
            if (found) {
              found.push(lessonItem)
            } else {
              courseRows.push([lessonItem])
            }
          }
        })
        this.$common.logData(courseRows, 'Next Month Courses')
        if (courseRows.length) {
          for (let i = 0; i < courseRows.length; i++) {
            let newCourse = this.parseCourseTableData(courseRows[i][0], courseRows[i])
            if (i !== 0) {
              newCourse.readonly = true
            }
            this.courseTableData.push(newCourse)
            this.courseSelectedRow.push({ id: newCourse.id })
          }
        }
      } catch (err) {
        this.$common.error(err)
      } finally {
        this.$store.dispatch('toggleLoadingPage', false)
        this.getStudentOrders()
      }
    },
    async getStudentOrders() {
      this.tableLoading = true
      // 攞 status !== 'paid' 嘅order
      try {
        const { data } = await this.$Fetcher.GetOrders({
          filter_item: [{ key: 'student_id', value: this.studentData.student_id }],
        })
        this.studentCourseOrders = data.filter(el => el.order_type === 'course')

        const excludeStatus = {
          paid: true,
          debit: true,
          cancelled: true,
        }
        const unpaidCourseOrders = data.filter(item => !excludeStatus[item.status] && item.order_type === 'course')
        this.$common.logData(unpaidCourseOrders, 'Unpaid Courses')
        if (unpaidCourseOrders.length) {
          const courseRows = [] // { id: [order id], lessons: []}
          unpaidCourseOrders.forEach(order => {
            if (order.item_list && order.item_list.length) {
              order.item_list.forEach(item => {
                const targetItem = order.item_datas.find(el => el.id === item.id)
                if (targetItem) {
                  const lessonItem = {
                    ...targetItem,
                    discount_type: item.discount_type,
                    discount_value: item.discount_type ? item.discount_value : null,
                    discount_price: null,
                    unit_price: targetItem.price,
                    order_id: order.id,
                    order_status: order.status,
                    order_payment_image: order.payment_image,
                    order_payment_method: order.payment_method,
                    order_remark: order.remark,
                  }
                  if (item.discount_type === 'percentage') {
                    lessonItem.discount_value = 100 - item.discount_value
                  }
                  lessonItem.discount_price = this.getDiscountPrice(
                    targetItem.price,
                    lessonItem.discount_type,
                    lessonItem.discount_value,
                  )

                  if (targetItem.lesson_type === 'regular') {
                    const date = new Date(targetItem.date)
                    const found = courseRows.find(el => {
                      const d = new Date(el.lessons[0].date)
                      return (
                        el.id === order.id &&
                        el.lessons[0].lesson_type === 'regular' &&
                        el.lessons[0].regular_id === targetItem.regular_id &&
                        d.getFullYear() === date.getFullYear() &&
                        d.getMonth() === date.getMonth()
                      )
                    })

                    if (found) {
                      found.lessons.push(lessonItem)
                    } else {
                      courseRows.push({
                        id: order.id,
                        lessons: [lessonItem],
                      })
                    }
                  } else {
                    courseRows.push({
                      id: order.id,
                      lessons: [lessonItem],
                    })
                  }
                }
              })
            }
          })

          let current_order = -1
          courseRows.forEach(item => {
            let newCourse = this.parseCourseTableData(item.lessons[0], item.lessons)
            if (current_order !== item.id) {
              current_order = item.id
            } else {
              newCourse.readonly = true
            }

            newCourse.discount_type = item.lessons[0].discount_type
            newCourse.discount_value = item.lessons[0].discount_value
            newCourse.discount_price = item.lessons[0].discount_price

            this.courseTableData.push(newCourse)
          })
        }

        const unpaidProductOrders = data.filter(item => !excludeStatus[item.status] && item.order_type === 'product')
        this.$common.logData(unpaidProductOrders, 'Unpaid Products')
        if (unpaidProductOrders.length) {
          const productRows = [] // { id: [order id], product: {}}
          unpaidProductOrders.forEach(order => {
            if (order.item_list && order.item_list.length) {
              order.item_list.forEach(item => {
                const targetItem = order.item_datas.find(el => el.id === item.id)
                if (targetItem) {
                  const productItem = {
                    ...targetItem,
                    discount_type: item.discount_type,
                    discount_value: item.discount_type ? item.discount_value : null,
                    discount_price: null,
                    unit_price: targetItem.price,
                    product_name: targetItem.name,
                    qty: item.amount,
                    order_id: order.id,
                    order_status: order.status,
                    order_payment_image: order.payment_image,
                    order_payment_method: order.payment_method,
                    order_remark: order.remark,
                  }
                  if (item.discount_type === 'percentage') {
                    productItem.discount_value = 100 - item.discount_value
                  }
                  productItem.discount_price = this.getDiscountPrice(
                    targetItem.price,
                    productItem.discount_type,
                    productItem.discount_value,
                  )

                  productRows.push({
                    id: order.id,
                    product: productItem,
                  })
                }
              })
            }
          })

          let current_order = -1
          productRows.forEach(item => {
            let newProduct = this.parseProductTableData(item.product)
            if (current_order !== item.id) {
              current_order = item.id
            } else {
              newProduct.readonly = true
            }

            newProduct.discount_type = item.product.discount_type
            newProduct.discount_value = item.product.discount_value
            newProduct.discount_price = item.product.discount_price

            this.productTableData.push(newProduct)
          })
        }

        const unpaidPackageOrders = data.filter(item => !excludeStatus[item.status] && item.order_type === 'package')
        this.$common.logData(unpaidPackageOrders, 'Unpaid Packages')
        if (unpaidPackageOrders.length) {
          const list = await this.getOrderPackages(unpaidPackageOrders)
          const packageRow = []
          list.forEach(order => {
            const packageItem = {
              id: order.package_id,
              package_name: order.package_name || '',
              lesson_count: order.lesson_count || '',
              unit_price: order.package_price || '',
              total: order.subtotal,
              discount_type: order.discount_type,
              discount_value: order.discount_type ? order.discount_value : null,
              discount_price: null,
              order_id: order.id,
              order_status: order.status,
              order_payment_image: order.payment_image,
              order_payment_method: order.payment_method,
              order_remark: order.remark,
            }

            if (order.discount_type === 'percentage') {
              packageItem.discount_value = 100 - order.discount_value
            }
            if (order.package_price) {
              packageItem.discount_price = this.getDiscountPrice(
                order.package_price,
                packageItem.discount_type,
                packageItem.discount_value,
              )
            }

            packageRow.push(packageItem)
          })

          packageRow.forEach(item => {
            let newPackage = this.parsePackageTableData(item)
            this.packageTableData.push(newPackage)
          })
        }
      } catch (err) {
        this.$common.error(err)
      } finally {
        this.tableLoading = false
      }
    },
    getOrderPackages(list) {
      return new Promise(async (resolve, reject) => {
        const packageOrders = list.filter(el => el.order_type === 'package')
        if (packageOrders.length) {
          try {
            const { data } = await this.$Fetcher.GetPackagesByIdList(packageOrders.map(el => el.package_id))
            list.forEach(item => {
              if (item.order_type === 'package') {
                const found = data.find(el => el.id === item.package_id)
                if (found) {
                  item.package_name = found.name
                  item.lesson_count = found.lesson_count
                  item.package_price = found.price
                }
              }
            })
            resolve(list)
          } catch (err) {
            this.$common.error(err)
            resolve(list)
          }
        } else {
          resolve(list)
        }
      })
    },

    onTabChange() {
      this.calPrice()
      this.setOrderDetails()
      this.orderDiscountType = ''
      this.orderDiscountValue = ''
    },

    setOrderDetails() {
      const orderIds = []
      if (this.currentTabType === 'course') {
        for (let i = 0; i < this.courseSelectedRow.length; i++) {
          const found = this.courseTableData.find(el => el.id === this.courseSelectedRow[i].id)
          if (found && !orderIds.includes(found.order_id)) {
            orderIds.push(found.order_id)
          }

          if (orderIds.length > 1) {
            break
          }
        }
      } else if (this.currentTabType === 'package') {
        for (let i = 0; i < this.packageSelectedRow.length; i++) {
          const found = this.packageTableData.find(el => el.id === this.packageSelectedRow[i].id)
          if (found && !orderIds.includes(found.order_id)) {
            orderIds.push(found.order_id)
          }

          if (orderIds.length > 1) {
            break
          }
        }
      } else if (this.currentTabType === 'product') {
        for (let i = 0; i < this.productSelectedRow.length; i++) {
          const found = this.productTableData.find(el => el.id === this.productSelectedRow[i].id)
          if (found && !orderIds.includes(found.order_id)) {
            orderIds.push(found.order_id)
          }

          if (orderIds.length > 1) {
            break
          }
        }
      }

      let found = null
      if (orderIds.length === 1 && orderIds[0] > -1) {
        if (this.currentTabType === 'course') {
          found = this.courseTableData.find(el => el.order_id === orderIds[0])
        } else if (this.currentTabType === 'package') {
          found = this.packageTableData.find(el => el.order_id === orderIds[0])
        } else if (this.currentTabType === 'product') {
          found = this.productTableData.find(el => el.order_id === orderIds[0])
        }
      }

      if (found) {
        this.remark = found.order_remark
        this.selectedStatus = found.order_status || 'pending'
        this.selectedPaymentMethod = found.order_payment_method || 'cash'
        if (found.order_payment_image) {
          this.paymentImages = [{ name: found.order_payment_image, file: found.order_payment_image }]
        }

        if (found.order_id > -1) {
          this.isUpdatePayment = true
          this.orderDiscountType = ''
          this.orderDiscountValue = ''
        }
      } else {
        this.remark = ''
        this.selectedStatus = 'paid'
        this.selectedPaymentMethod = 'cash'
        this.paymentImages = []
        this.isUpdatePayment = false
      }
    },

    calPrice() {
      let selectedIds = []
      let selectedRows = []

      if (this.currentTabType === 'course') {
        selectedIds = this.courseSelectedRow.map(el => el.id)
        selectedRows = this.courseTableData.filter(el => selectedIds.includes(el.id))
      } else if (this.currentTabType === 'package') {
        selectedIds = this.packageSelectedRow.map(el => el.id)
        selectedRows = this.packageTableData.filter(el => selectedIds.includes(el.id))
      } else if (this.currentTabType === 'product') {
        selectedIds = this.productSelectedRow.map(el => el.id)
        selectedRows = this.productTableData.filter(el => selectedIds.includes(el.id))
      }

      this.subtotal = selectedRows.reduce((count, item) => count + this.getUnitPrice(item) * item.qty, 0)
      this.qty = selectedRows.reduce((count, item) => count + item.qty, 0)
    },

    getUnitPrice(item) {
      return this.$validate.DataValid(item.discount_price) ? item.discount_price : item.unit_price
    },

    getDiscountPrice(price, discount_type, discount_value = 0) {
      // price after discount
      let p = 0
      if (this.$validate.regexFloating(price) && this.$validate.regexFloating(discount_value) && discount_type) {
        if (discount_type === 'percentage') {
          p = this.$formatter.formatMoney((parseFloat(price) * (100 - parseFloat(discount_value))) / 100)
        } else if (discount_type === 'fix_amount') {
          p = this.$formatter.formatMoney(parseFloat(price) - parseFloat(discount_value))
        }

        if (p < 0) {
          p = 0
        }

        return p
      }

      return null
    },

    getDiscountLabel(price, discount_type, discount_value = 0) {
      let s = this.$t('discount')

      if (
        this.$validate.DataValid(discount_type) &&
        this.$validate.regexFloating(discount_value) &&
        this.$validate.regexFloating(price)
      ) {
        if (discount_type === 'percentage') {
          s += ' ('
          if (this.$i18n.locale === 'en') {
            s += `${discount_value}% off`
          } else {
            const off = 100 - parseFloat(discount_value)
            s += `${off <= 0 ? 10 : parseFloat((off * 0.1).toFixed(1))}折`
          }
          s += ')'
        } else if (discount_type === 'fix_amount') {
          const discountPrice = this.getDiscountPrice(price, discount_type, discount_value)
          s += ` (-$ ${this.$formatter.formatMoney(parseFloat(price) - parseFloat(discountPrice || '0'))})`
        }
      }

      return s
    },

    async openAddSearchDialog() {
      if (this.$store.getters.isLoading) {
        return
      }
      // -- reset table
      this.searchTableOptions.tablePage = 1
      this.searchTableTotal = 0
      this.searchTableData = []

      // -- reset course search
      this.addCourseFilter.type = 'weekday'
      this.clearAddCourseFilter()

      // -- reset product search
      this.addProductKeyword = ''

      // -- reset package search
      this.addPackageKeyword = ''
      this.tempSelectedPackage = null

      // -- open dialog
      if (this.currentTabType === 'course') {
        this.addCourseDialogOpen = true
        this.addProductDialogOpen = false
        this.addPackageDialogOpen = false
        this.isSearching = true
        this.searchTableData = []
        if (this.tutorOptions.length < 1) {
          await this.getTutorOptions()
        }
        this.searchCourses()
      } else if (this.currentTabType === 'product') {
        this.addProductDialogOpen = true
        this.addCourseDialogOpen = false
        this.addPackageDialogOpen = false
        this.searchProducts()
      } else if (this.currentTabType === 'package') {
        this.addPackageDialogOpen = true
        this.addProductDialogOpen = false
        this.addCourseDialogOpen = false
        this.isSearching = true
        this.searchTableData = []
        if (this.remainPackageLessonCountInit) {
          await this.getStudentRemainLessonCount()
        }
        if (this.tutorOptions.length < 1) {
          await this.getTutorOptions()
        }
        this.searchPackages()
      } else {
        this.addProductDialogOpen = false
        this.addCourseDialogOpen = false
        this.addPackageDialogOpen = false
      }
    },
    async getTutorOptions() {
      try {
        const { data } = await this.$Fetcher.GetTutors({ center: [this.studentData.school_id] })
        this.tutorOptions = data.map(el => {
          return {
            value: el.id,
            text: el.name,
          }
        })
        this.tutorOptions.unshift({ value: -1, text: this.$t('allTutors') })
      } catch {}
    },

    async getStudentRemainLessonCount() {
      try {
        const data = await this.$Fetcher.GetStudentPackageQuota(this.studentData.student_id)
        this.remainPackageLessonCount = data
      } catch (err) {
        this.$common.error(err)
      } finally {
        this.remainPackageLessonCountInit = false
      }
    },

    onSearchTableOptionsChange(options) {
      if (options.itemsPerPage !== this.searchTableOptions.tableLimit) {
        this.searchTableOptions.tablePage = 1
      } else {
        this.searchTableOptions.tablePage = options.page
      }

      this.searchTableOptions.tableLimit = options.itemsPerPage

      if (this.addCourseDialogOpen) {
        this.searchCourses()
      } else if (this.addPackageDialogOpen) {
        this.searchPackages()
      } else if (this.addProductDialogOpen) {
        this.searchProducts()
      }
    },

    // ========= 修改項目資料 =========
    async handleEditOpen(id, type) {
      if (this.$store.getters.isLoading) {
        return
      }

      // reset data
      this.editDiscountType = ''
      this.editDiscountValue = ''
      this.editOriginalPrice = ''
      this.editTableData = []
      this.editCourseSelectedRow = []
      this.$refs['editFormDialog'].setEditId(id)
      await this.$refs['editFormDialog'].resetForm()

      this.editTableLoading = true

      let target = null
      if (type === 'course') {
        target = this.courseTableData.find(el => el.id === id)
        if (target) {
          this.$common.logData(target, '修改項目資料 (課程)')

          this.editTableData = target.lesson_data_list.map(lessonData => {
            return {
              id: lessonData.id,
              date: lessonData.date,
              start_time: lessonData.start_time,
              end_time: lessonData.end_time,
            }
          })

          this.editCourseSelectedRow = this.editTableData.filter(el => target.selected_lesson_id_list.includes(el.id))
        }
      } else if (type === 'product') {
        // -- Edit product item
        target = this.productTableData.find(el => el.id === id)
        if (target) {
          this.$common.logData(target, '修改項目資料 (商品)')

          // -- edit product table data
          this.editTableData = [
            {
              id: target.id,
              product_name: target.product_name,
              qty: target.qty,
              inventory: target.inventory,
            },
          ]
        }
      } else if (type === 'package') {
        target = this.packageTableData.find(el => el.id === id)
        if (target) {
          this.$common.logData(target, '修改項目資料 (套票)')
          this.editTableData = target.lesson_data_list.map(lessonData => {
            return {
              id: lessonData.id,
              course_name: lessonData.course_name,
              date: lessonData.date,
              start_time: lessonData.start_time,
              end_time: lessonData.end_time,
            }
          })
        }
      }

      // set price details
      if (target) {
        if (this.$validate.regexFloating(target.unit_price)) {
          this.editOriginalPrice = target.unit_price.toString()
        }

        if (this.$validate.regexFloating(target.discount_value)) {
          this.editDiscountValue = target.discount_value.toString()
        }

        if (this.$validate.DataValid(target.discount_type)) {
          this.editDiscountType = target.discount_type
        }
      }

      this.editTableLoading = false
      this.$refs['editFormDialog'].show()
    },

    handleEditSubmit() {
      const editId = this.$refs['editFormDialog'].getEditId()
      if (this.$validate.DataValid(editId)) {
        let target = null
        if (this.currentTabType === 'course') {
          if (this.editCourseSelectedRow.length < 1) {
            this.setDialogMessage({
              title: 'message.operationalError',
              message: 'message.chooseAtLeastOneLesson',
              isError: true,
              returnLink: null,
            })
            this.setShowDialog(true)
            this.$refs['editFormDialog'].setLoading(false)
            return
          }

          target = this.courseTableData.find(el => el.id === editId)
          // - update selected date class
          target.selected_lesson_id_list = this.editCourseSelectedRow.map(el => el.id)
          target.dates = this.editCourseSelectedRow.map(el => el.date)
          target.qty = target.selected_lesson_id_list.length
        } else if (this.currentTabType === 'product') {
          target = this.productTableData.find(el => el.id === editId)
          const newUpdate = this.editTableData.find(el => el.id === editId)
          if (newUpdate && target) {
            target.qty = newUpdate.qty
          }
        } else if (this.currentTabType === 'package') {
          target = this.packageTableData.find(el => el.id === editId)
        }

        if (target) {
          target.discount_type = this.editDiscountType
          target.discount_value = this.$validate.regexFloating(this.editDiscountValue)
            ? parseFloat(this.editDiscountValue)
            : null
          target.discount_price = this.$validate.regexFloating(this.editDiscountPrice)
            ? parseFloat(this.editDiscountPrice)
            : null
          target.total = this.$formatter.formatMoney(this.getUnitPrice(target) * target.qty)
        }
      }

      this.$refs['editFormDialog'].hide()
    },

    async handleDeleteAction(delete_id) {
      const confirm = await this.$refs.deleteDialog.show()

      if (confirm) {
        if (this.currentTabType === 'course') {
          const targetPos = this.courseTableData.findIndex(el => el.id === delete_id)
          if (targetPos > -1) {
            if (
              this.courseTableData[targetPos + 1] &&
              this.courseTableData[targetPos + 1].order_id !== -1 &&
              this.courseTableData[targetPos].order_id !== -1 &&
              this.courseTableData[targetPos + 1].order_id === this.courseTableData[targetPos].order_id &&
              !this.courseTableData[targetPos].readonly &&
              this.courseTableData[targetPos + 1].readonly
            ) {
              this.courseTableData[targetPos + 1].readonly = false
            }
            this.courseTableData.splice(targetPos, 1)
          }

          const targetSelectedPos = this.courseSelectedRow.findIndex(el => el.id === delete_id)
          if (targetSelectedPos > -1) {
            this.courseSelectedRow.splice(targetSelectedPos, 1)
          }
        } else if (this.currentTabType === 'product') {
          const targetPos = this.productTableData.findIndex(el => el.id === delete_id)
          if (targetPos > -1) {
            if (
              this.productTableData[targetPos + 1] &&
              this.productTableData[targetPos + 1].order_id !== -1 &&
              this.productTableData[targetPos].order_id !== -1 &&
              this.productTableData[targetPos + 1].order_id === this.productTableData[targetPos].order_id &&
              !this.productTableData[targetPos].readonly &&
              this.productTableData[targetPos + 1].readonly
            ) {
              this.productTableData[targetPos + 1].readonly = false
            }

            this.productTableData.splice(targetPos, 1)
          }

          const targetSelectedPos = this.productSelectedRow.findIndex(el => el.id === delete_id)
          if (targetSelectedPos > -1) {
            this.productSelectedRow.splice(targetSelectedPos, 1)
          }
        } else if (this.currentTabType === 'package') {
          const targetPos = this.packageTableData.findIndex(el => el.id === delete_id)
          if (targetPos > -1) {
            if (
              this.packageTableData[targetPos + 1] &&
              this.packageTableData[targetPos + 1].order_id !== -1 &&
              this.packageTableData[targetPos].order_id !== -1 &&
              this.packageTableData[targetPos + 1].order_id === this.packageTableData[targetPos].order_id &&
              !this.packageTableData[targetPos].readonly &&
              this.packageTableData[targetPos + 1].readonly
            ) {
              this.packageTableData[targetPos + 1].readonly = false
            }

            this.packageTableData.splice(targetPos, 1)
          }

          const targetSelectedPos = this.packageSelectedRow.findIndex(el => el.id === delete_id)
          if (targetSelectedPos > -1) {
            this.packageSelectedRow.splice(targetSelectedPos, 1)
          }
        }
      }
    },

    // ========= Course Related =========
    parseCourseTableData(item, lessonData = []) {
      this.rowId += 1
      let valid_lessons = JSON.parse(JSON.stringify(lessonData))
      if (!this.$validate.DataValid(item.order_id)) {
        valid_lessons = valid_lessons.filter(el => {
          if (el.id === item.id) {
            return true
          } else {
            // --- filter 走過咗期嘅lesson
            const date = this.$formatter.convertStrToDate(el.date)
            const today = new Date()
            if (date.getFullYear() < today.getFullYear()) {
              return false
            } else if (date.getFullYear() === today.getFullYear()) {
              if (date.getMonth() < today.getMonth()) {
                return false
              } else if (date.getMonth() === today.getMonth()) {
                if (date.getDate() < today.getDate()) {
                  return false
                }
              }
            }

            return true
          }
        })
      }

      return {
        id: this.rowId,
        course_name: item.course_name,
        lesson_type: item.lesson_type,
        lesson_data_list: lessonData,
        selected_lesson_id_list: valid_lessons.map(el => el.id),
        year: item.date.substring(0, 4),
        month: item.date.substring(5, 7),
        dates: valid_lessons.map(el => el.date),
        unit_price: item.unit_price,
        qty: valid_lessons.length,
        total: this.$formatter.formatMoney(this.getUnitPrice(item) * valid_lessons.length),
        discount_type: '',
        discount_value: null,
        discount_price: null,
        order_id: this.$validate.DataValid(item.order_id) ? item.order_id : -1,
        order_status: item.order_status,
        order_payment_image: item.order_payment_image,
        order_payment_method: item.order_payment_method,
        order_remark: item.order_remark,
      }
    },

    onCourseSelectedRowChanged() {
      this.courseTableData.forEach(el => (el.disabled = false))
      let order_id = -1
      let selected = false

      for (let i = 0; i < this.courseTableData.length; i++) {
        const item = this.courseTableData[i]
        const pos = this.courseSelectedRow.findIndex(el => el.id === item.id)
        if (order_id !== item.order_id) {
          order_id = item.order_id

          if (pos > -1) {
            selected = true

            if (item.order_id > -1) {
              this.courseSelectedRow = this.courseTableData
                .filter(el => el.order_id === item.order_id)
                .map(el => {
                  return { id: el.id }
                })
              this.courseTableData.forEach(el => {
                if (el.order_id !== item.order_id) {
                  el.disabled = true
                }
              })
              break
            }
          } else {
            selected = false
          }
        } else if (item.order_id !== -1) {
          if (selected && pos < 0) {
            this.courseSelectedRow.push({ id: item.id })
          } else if (!selected && pos > -1) {
            this.courseSelectedRow.splice(pos, 1)
          }
        }
      }

      this.setOrderDetails()
    },

    // -- 報讀新課程
    clearAddCourseFilter() {
      this.addCourseFilter.weekdays = []
      this.addCourseFilter.dateRange = []
      this.addCourseFilter.keyword = ''
      this.addCourseFilter.tutor = ''
      this.addCourseFilter.grade = this.studentData.grade
      this.searchTableOptions.tablePage = 1
      this.searchTableTotal = 0
      this.searchTableData = []
      this.searchTableSelectedRow = []
    },

    onCourseTypeChange() {
      this.clearAddCourseFilter()
      this.searchCourses()
    },
    onCourseFilterChange() {
      this.searchTableOptions.tablePage = 1
      this.searchTableTotal = 0
      this.searchCourses()
    },

    async searchCourses() {
      this.isSearching = true
      this.searchTableData = []

      let payload = {
        filter_page: this.searchTableOptions.tablePage - 1,
        filter_limit: this.searchTableOptions.tableLimit,
        center_id: this.studentData.school_id,
        join_tutor_data: true,
        filter_item: [],
      }

      if (this.currentTabType === 'package') {
        payload.filter_item.push({ key: 'start_date', value: this.$formatter.formatDate(new Date()) })
      } else {
        const current_month = new Date()
        current_month.setDate(1)
        payload.filter_item.push({ key: 'start_date', value: this.$formatter.formatDate(current_month) })
      }

      if (this.$validate.DataValid(this.addCourseFilter.tutor) && this.addCourseFilter.tutor > -1) {
        payload.filter_item.push({ key: 'staff_id', value: this.addCourseFilter.tutor })
      }

      if (
        this.$validate.DataValid(this.addCourseFilter.keywordType) &&
        this.$validate.DataValid(this.addCourseFilter.keyword)
      ) {
        payload.filter_item.push({ key: this.addCourseFilter.keywordType, value: this.addCourseFilter.keyword })
      }
      if (this.$validate.DataValid(this.addCourseFilter.grade)) {
        payload.filter_item.push({ key: 'grade', value: this.addCourseFilter.grade })
      }

      if (this.addCourseFilter.type === 'weekday') {
        payload.filter_item.push({ key: 'lesson_type', value: 'regular' })
        if (this.$validate.DataValid(this.addCourseFilter.weekdays)) {
          const weekdays = this.addCourseFilter.weekdays.map(el => parseInt(el))
          payload.filter_item.push({ key: 'weekdays', value: weekdays })
        }
      } else if (this.addCourseFilter.type === 'singleDate') {
        payload.filter_item.push({ key: 'lesson_type', value: 'single' })
        if (this.$validate.DataValid(this.addCourseFilter.dateRange)) {
          if (this.addCourseFilter.dateRange[0]) {
            payload.filter_item.push({ key: 'start_date', value: this.addCourseFilter.dateRange[0] })
          }
          if (this.addCourseFilter.dateRange[1]) {
            payload.filter_item.push({ key: 'end_date', value: this.addCourseFilter.dateRange[1] })
          }
        }
      }

      try {
        const { data, total } = await this.$Fetcher.GetLessons(payload)
        this.searchTableTotal = total
        this.searchTableData = data.map(el => {
          let selected = false
          if (this.currentTabType === 'course' && this.$validate.DataValid(this.courseTableData)) {
            for (let i = 0; i < this.courseTableData.length; i++) {
              if (
                this.$validate.DataValid(this.courseTableData[i].selected_lesson_id_list) &&
                this.courseTableData[i].selected_lesson_id_list.includes(el.id)
              ) {
                selected = true
                break
              }
            }
          } else if (this.currentTabType === 'package' && this.$validate.DataValid(this.searchTableSelectedRow)) {
            const found = this.searchTableSelectedRow.find(elm => elm.id === el.id)
            if (found) {
              selected = true
            }
          }

          return {
            id: el.id, // lesson id
            lesson_type: el.lesson_type,
            course_id: el.course_id,
            regular_id: el.regular_id,
            course_name: el.course_name,
            course_code: el.course_code,
            date: el.date,
            start_time: el.start_time,
            end_time: el.end_time,
            grade: el.grade,
            tutor: el.tutor_data.name,
            current_student_num: el.current_student_num,
            max_student: el.max_student,
            unit_price: el.price,
            selected: selected,
            applied: el.student_ids.includes(this.id),
          }
        })
      } catch (err) {
        this.$common.error(err)
      } finally {
        this.isSearching = false
      }
    },

    async handleAddNewCourse(lesson_id) {
      const targetLesson = this.searchTableData.find(el => el.id === lesson_id)
      this.$common.logData(targetLesson, '報讀新課程')
      if (this.$validate.DataValid(targetLesson)) {
        if (targetLesson.applied) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: 'message.lessonRepeat',
            type: 'error',
            refresh: false,
            redirect: '',
          })
          return
        }
        if (targetLesson.current_student_num >= targetLesson.max_student) {
          const confirm = await this.$refs.exceedStudentDialog.show()
          if (!confirm) {
            return
          }
        }

        if (targetLesson.lesson_type === 'regular') {
          if (this.currentTabType === 'course') {
            this.tableLoading = true
            this.$store.dispatch('setLoading', true)
            try {
              // --- Get related lesson
              const targetYear = targetLesson.date.substring(0, 4)
              const targetMonth = targetLesson.date.substring(5, 7)
              const lastDateOfMonth = new Date(parseInt(targetYear), parseInt(targetMonth), 0)
              let payload = {
                center_id: this.studentData.school_id,
                filter_item: [
                  { key: 'course_code', value: targetLesson.course_code },
                  { key: 'course_id', value: targetLesson.course_id },
                  { key: 'lesson_type', value: 'regular' },
                  { key: 'start_date', value: `${targetYear}-${targetMonth}-01` },
                  {
                    key: 'end_date',
                    value: `${targetYear}-${targetMonth}-${this.$formatter.pad(lastDateOfMonth.getDate())}`,
                  },
                ],
              }

              const { data } = await this.$Fetcher.GetLessons(payload)
              const relatedLesson = data.filter(el => !el.student_ids.includes(this.id))
              this.$common.logData(relatedLesson, '相關課堂')

              //-- find if related lesson added
              let addNewCourse = false
              if (this.$validate.DataValid(this.courseTableData)) {
                const relatedLessonIds = relatedLesson.map(el => el.id)
                let courseAdded = null
                for (let i = 0; i < this.courseTableData.length; i++) {
                  const rowRelatedLesson = this.courseTableData[i].selected_lesson_id_list
                  for (let k = 0; k < relatedLessonIds.length; k++) {
                    if (rowRelatedLesson.includes(relatedLessonIds[k])) {
                      courseAdded = this.courseTableData[i]
                      break
                    }
                  }
                }

                if (courseAdded !== null) {
                  // 如果相關課程已added，將lesson加至該課程的selected_lesson_id_list中
                  courseAdded.selected_lesson_id_list.push(targetLesson.id)
                  courseAdded.dates.push(targetLesson.date)
                  courseAdded.dates.sort((a, b) => {
                    const aDate = this.$formatter.convertStrToDate(a)
                    const bDate = this.$formatter.convertStrToDate(b)
                    return aDate.getTime() < bDate.getTime()
                  })
                  courseAdded.qty += 1
                  courseAdded.total = this.$formatter.formatMoney(this.getUnitPrice(courseAdded) * courseAdded.qty)
                } else {
                  // 否則，加新課程，並把所有相關lesson加至該課程的selected_lesson_id_list中（包括target lesson）
                  addNewCourse = true
                }
              } else {
                addNewCourse = true
              }

              if (addNewCourse === true) {
                const newCourse = this.parseCourseTableData(targetLesson, relatedLesson)
                this.courseTableData.push(newCourse)
                this.courseSelectedRow.push({ id: newCourse.id })
              }
            } catch (err) {
              this.$common.error(err)
            } finally {
              this.tableLoading = false
              this.$store.dispatch('setLoading', false)
            }
          } else if (this.currentTabType === 'package') {
            this.handlePackageLessonSelected(lesson_id)
            return
          }
        } else {
          let addNewCourse = false
          if (this.$validate.DataValid(this.courseTableData)) {
            const existed = this.courseTableData.find(
              el => el.lesson_data_list[0] && el.lesson_data_list[0].id === targetLesson.id,
            )
            if (!existed) {
              addNewCourse = true
            }
          } else {
            addNewCourse = true
          }

          if (addNewCourse === true) {
            const formattedLessonData = JSON.parse(JSON.stringify(targetLesson))
            const newCourse = this.parseCourseTableData(targetLesson, [formattedLessonData])
            this.courseTableData.push(newCourse)
            this.courseSelectedRow.push({ id: newCourse.id })
          }
        }
      }

      this.addCourseDialogOpen = false
    },

    // ========= Product Related =========
    parseProductTableData(item) {
      this.rowId += 1
      return {
        id: this.rowId,
        product_id: item.id,
        product_name: item.product_name,
        qty: item.qty,
        inventory: item.inventory,
        unit_price: item.unit_price,
        total: this.$formatter.formatMoney(this.getUnitPrice(item) * item.qty),
        discount_type: '',
        discount_value: null,
        discount_price: null,
        order_id: this.$validate.DataValid(item.order_id) ? item.order_id : -1,
        order_status: item.order_status,
        order_payment_image: item.order_payment_image,
        order_payment_method: item.order_payment_method,
        order_remark: item.order_remark,
      }
    },

    onProductSelectedRowChanged() {
      this.productTableData.forEach(el => (el.disabled = false))
      let order_id = -1
      let selected = false

      for (let i = 0; i < this.productTableData.length; i++) {
        const item = this.productTableData[i]
        const pos = this.productSelectedRow.findIndex(el => el.id === item.id)
        if (order_id !== item.order_id) {
          order_id = item.order_id

          if (pos > -1) {
            selected = true

            if (item.order_id > -1) {
              this.productSelectedRow = this.productTableData
                .filter(el => el.order_id === item.order_id)
                .map(el => {
                  return { id: el.id }
                })
              this.productTableData.forEach(el => {
                if (el.order_id !== item.order_id) {
                  el.disabled = true
                }
              })
              break
            }
          } else {
            selected = false
          }
        } else if (item.order_id !== -1) {
          if (selected && pos < 0) {
            this.productSelectedRow.push({ id: item.id })
          } else if (!selected && pos > -1) {
            this.productSelectedRow.splice(pos, 1)
          }
        }
      }

      this.setOrderDetails()
    },

    // -- 新增產品
    onProductFilterChange() {
      this.searchTableOptions.tablePage = 1
      this.searchTableTotal = 0
      this.searchProducts()
    },

    async searchProducts() {
      this.isSearching = true
      this.searchTableData = []

      let payload = {
        filter_page: this.searchTableOptions.tablePage - 1,
        filter_limit: this.searchTableOptions.tableLimit,
        center_id: this.studentData.school_id,
        filter_item: [{ key: 'status', value: 'active' }],
      }
      if (this.$validate.DataValid(this.addProductKeyword)) {
        payload.filter_item.push({ key: 'name', value: this.addProductKeyword })
      }

      try {
        const { data, total } = await this.$Fetcher.GetProducts(payload)
        this.searchTableTotal = total
        this.searchTableData = data.map(el => {
          const selected = this.productTableData.find(item => item.product_id === el.id)
          let qty = 1
          if (selected) {
            qty = selected.qty
          }
          return {
            id: el.id,
            product_name: el.name,
            inventory: el.inventory,
            qty: qty,
            unit_price: el.price,
          }
        })
      } catch (err) {
        this.$common.error(err)
      } finally {
        this.isSearching = false
      }
    },

    handleAddNewProduct(id) {
      const targetProduct = this.searchTableData.find(el => el.id === id)
      if (targetProduct) {
        const existed = this.productTableData.find(el => el.product_id === id && el.order_id < 0)
        if (existed) {
          existed.qty = targetProduct.qty
          existed.total = this.$formatter.formatMoney(this.getUnitPrice(existed) * existed.qty)
        } else {
          const newProduct = this.parseProductTableData(targetProduct)
          this.productTableData.push(newProduct)
          this.productSelectedRow.push({ id: newProduct.id })
        }
      }

      this.addProductDialogOpen = false
    },

    // ========= Package Related =========
    parsePackageTableData(item) {
      this.rowId += 1
      return {
        id: this.rowId,
        package_id: item.id,
        package_name: item.package_name,
        lesson_count: item.lesson_count,
        unit_price: item.unit_price,
        total: item.total,
        lesson_data_list: item.selected_lessons || [],
        max_lesson_count: item.max_lesson_count || item.lesson_count,
        discount_type: item.discount_type,
        discount_value: item.discount_value,
        discount_price: item.discount_price,
        order_id: this.$validate.DataValid(item.order_id) ? item.order_id : -1,
        order_status: item.order_status,
        order_payment_image: item.order_payment_image,
        order_payment_method: item.order_payment_method,
        order_remark: item.order_remark,
        qty: 1,
        dates: item.selected_lessons.map(el => el.date)
      }
    },

    onPackageSelectedRowChanged() {
      this.packageTableData.forEach(el => (el.disabled = false))
      let order_id = -1
      let selected = false

      for (let i = 0; i < this.packageTableData.length; i++) {
        const item = this.packageTableData[i]
        const pos = this.packageSelectedRow.findIndex(el => el.id === item.id)
        if (order_id !== item.order_id) {
          order_id = item.order_id

          if (pos > -1) {
            selected = true

            if (item.order_id > -1) {
              this.packageSelectedRow = this.packageTableData
                .filter(el => el.order_id === item.order_id)
                .map(el => {
                  return { id: el.id }
                })
              this.packageTableData.forEach(el => {
                if (el.order_id !== item.order_id) {
                  el.disabled = true
                }
              })
              break
            }
          } else {
            selected = false
          }
        } else if (item.order_id !== -1) {
          if (selected && pos < 0) {
            this.packageSelectedRow.push({ id: item.id })
          } else if (!selected && pos > -1) {
            this.packageSelectedRow.splice(pos, 1)
          }
        }
      }

      this.setOrderDetails()
    },

    // -- 選擇套票
    onPackageFilterChange() {
      this.searchTableOptions.tablePage = 1
      this.searchTableTotal = 0
      this.searchPackages()
    },

    async searchPackages() {
      this.isSearching = true
      this.searchTableData = []

      let payload = {
        filter_page: this.searchTableOptions.tablePage - 1,
        filter_limit: this.searchTableOptions.tableLimit,
        filter_item: [{ key: 'center_id', value: this.studentData.school_id }],
      }
      if (this.$validate.DataValid(this.addPackageKeyword)) {
        payload.filter_item.push({ key: 'package_name', value: this.addPackageKeyword })
      }

      try {
        const { data, total } = await this.$Fetcher.GetPackages(payload)
        this.searchTableTotal = total
        this.searchTableData = data.map(el => {
          return {
            id: el.id,
            package_name: el.name,
            lesson_count: el.lesson_count,
            unit_price: el.price,
            total: el.price,
          }
        })
      } catch (err) {
        this.$common.error(err)
      } finally {
        this.isSearching = false
      }
    },

    async handleAddNewPackage(id) {
      const targetPackage = this.searchTableData.find(el => el.id === id)
      if (targetPackage) {
        this.tempSelectedPackage = targetPackage
        this.tempSelectedPackage.max_lesson_count = targetPackage.lesson_count
        this.tempSelectedPackage.selected_lessons = []
      }

      this.addPackageDialogOpen = false

      // -- reset table
      this.searchTableOptions.tablePage = 1
      this.searchTableTotal = 0
      this.searchTableData = []

      // -- reset course search
      this.addCourseFilter.type = 'weekday'
      this.clearAddCourseFilter()

      this.unpaidLessonTableData = []
      this.unpaidLessonSelectedRow = []
      this.allApplyCount = 0

      try {
        const data = await this.$Fetcher.GetStudentUnpaidLessons(this.studentData.student_id)

        // filter走已有order的lesson
        data.forEach(lesson => {
          const found = this.studentCourseOrders.find(el => {
            const temp = el.item_list.find(elm => elm.id === lesson.id)
            return temp ? true : false
          })

          if (!found) {
            this.unpaidLessonTableData.push(lesson)
          }
        })

        this.$common.logData(this.unpaidLessonTableData, 'Student Unpaid Lessons')
        this.unpaidLessonTableData.sort((a, b) => {
          const aDate = new Date(`${a.date}T${a.start_time}:00+08:00`)
          const bDate = new Date(`${b.date}T${b.start_time}:00+08:00`)
          return aDate.getTime() - bDate.getTime()
        })

        if (this.unpaidLessonTableData.length > 0) {
          this.$refs.unpaidLessonFormDialog.show()
        } else {
          this.addCourseDialogOpen = true
          this.searchCourses()
        }
      } catch (err) {
        this.$common.error(err)
        this.addCourseDialogOpen = true
        this.searchCourses()
      }
    },

    async handleAddMoreLesson(submit) {
      if (submit === true) {
        this.$store.dispatch('setLoading', true)

        try {
          const lessons = await this.$Fetcher.ExtendRegularLesson(
            this.tempSelectedPackage.selected_lessons[0].regular_id,
            parseInt(this.createLessonCount),
          )
          this.tempSelectedPackage.selected_lessons = [...this.tempSelectedPackage.selected_lessons, ...lessons]

          setTimeout(() => {
            this.$refs.addLessonFormDialog.hide()
            this.$store.dispatch('setLoading', false)
            this.addNewPackage()
          }, 500)
        } catch (err) {
          this.$common.error(err)
          this.setDialogMessage({
            title: 'message.createFail',
            message: err,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
          this.$store.dispatch('setLoading', false)
          this.$refs.addLessonFormDialog.setLoading(false)
        }
      } else {
        this.addNewPackage()
      }
    },

    async handleUnpaidLessonSelected(action) {
      this.tempSelectedPackage.selected_lessons = []
      this.unpaidLessonSelectedRow.forEach(item => {
        const found = this.unpaidLessonTableData.find(el => el.id === item.id)
        if (found) {
          this.tempSelectedPackage.selected_lessons.push(found)
        }
      })

      if (action === true) {
        if (this.unpaidLessonSelectedRow.length < 1) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: 'message.chooseAtLeastOneLesson',
            type: 'error',
            refresh: false,
            redirect: '',
          })
          this.$refs.unpaidLessonFormDialog.setLoading(false)
          return
        }

        if (this.unpaidLessonSelectedRow.length > this.tempSelectedPackage.lesson_count) {
          if (
            this.remainPackageLessonCount + this.tempSelectedPackage.lesson_count >=
            this.unpaidLessonSelectedRow.length
          ) {
            this.allApplyCount = this.unpaidLessonSelectedRow.length
            const confirmCombine = await this.$refs['combineRemainDialog'].show()
            if (confirmCombine) {
              this.tempSelectedPackage.max_lesson_count =
                this.tempSelectedPackage.lesson_count + this.remainPackageLessonCount
            } else {
              this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: 'message.lessonCountNotEnough',
                type: 'error',
                refresh: false,
                redirect: '',
              })
              this.$refs.unpaidLessonFormDialog.setLoading(false)
              return
            }
          } else {
            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: 'message.lessonCountNotEnough',
              type: 'error',
              refresh: false,
              redirect: '',
            })
            this.$refs.unpaidLessonFormDialog.setLoading(false)
            return
          }
        }
      } else {
        // confirm & continue apply
        if (this.unpaidLessonSelectedRow.length === this.tempSelectedPackage.lesson_count) {
          if (this.remainPackageLessonCount > 0) {
            // search course
            this.$refs.unpaidLessonFormDialog.hide()
            this.addCourseDialogOpen = true
            this.searchCourses()
            return
          }
        } else if (this.unpaidLessonSelectedRow.length > this.tempSelectedPackage.lesson_count) {
          if (
            this.remainPackageLessonCount + this.tempSelectedPackage.lesson_count >=
            this.unpaidLessonSelectedRow.length
          ) {
            this.allApplyCount = this.unpaidLessonSelectedRow.length
            const confirmCombine = await this.$refs['combineRemainDialog'].show()

            if (confirmCombine) {
              this.tempSelectedPackage.max_lesson_count =
                this.tempSelectedPackage.lesson_count + this.remainPackageLessonCount

              if (this.tempSelectedPackage.max_lesson_count > this.unpaidLessonSelectedRow.length) {
                // search course
                this.$refs.unpaidLessonFormDialog.hide()
                this.addCourseDialogOpen = true
                this.searchCourses()
                return
              }
            } else {
              this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: 'message.lessonCountNotEnough',
                type: 'error',
                refresh: false,
                redirect: '',
              })
              this.$refs.unpaidLessonFormDialog.setLoading(false)
              return
            }
          } else {
            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: 'message.lessonCountNotEnough',
              type: 'error',
              refresh: false,
              redirect: '',
            })
            this.$refs.unpaidLessonFormDialog.setLoading(false)
            return
          }
        } else {
          // search course
          this.$refs.unpaidLessonFormDialog.hide()
          this.addCourseDialogOpen = true
          this.searchCourses()
          return
        }
      }

      this.addNewPackage()
      this.$refs.unpaidLessonFormDialog.hide()
    },

    handlePackageLessonSelected(lesson_id) {
      // 揀左其中一堂後會幫佢報晒之後所有堂 (exclude 已經有嘅lesson)
      const targetLesson = this.searchTableData.find(el => el.id === lesson_id)

      let add = true
      if (this.searchTableSelectedRow.length > 0) {
        for (let i = this.searchTableSelectedRow.length - 1; i >= 0; i--) {
          if (this.searchTableSelectedRow[i].id === targetLesson.id) {
            this.searchTableSelectedRow.splice(i, 1)
            add = false
            break
          } else if (this.searchTableSelectedRow[i].course_code === targetLesson.course_code) {
            this.searchTableSelectedRow[i].id = targetLesson.id
            this.searchTableSelectedRow[i].course_code = targetLesson.course_code
            this.searchTableSelectedRow[i].date = targetLesson.date
            add = false
            break
          }
        }
      }
      if (add) {
        this.searchTableSelectedRow.push({
          id: targetLesson.id,
          course_code: targetLesson.course_code,
          date: targetLesson.date,
        })
      }

      this.searchTableSelectedRow = this.searchTableSelectedRow.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
      )

      for (let i = 0; i < this.searchTableData.length; i++) {
        const found = this.searchTableSelectedRow.find(el => el.id === this.searchTableData[i].id)
        if (found) {
          this.searchTableData[i].selected = true
        } else {
          this.searchTableData[i].selected = false
        }

        this.searchTableData[i].disabled = false
        if (this.searchTableSelectedRow.length > 0) {
          const date = new Date(this.searchTableData[i].date)
          const minDate = new Date(this.searchTableSelectedRow[0].date)
          if (date.getFullYear() < minDate.getFullYear()) {
            this.searchTableData[i].disabled = true
          } else if (date.getFullYear() === minDate.getFullYear()) {
            if (
              date.getMonth() < minDate.getMonth() ||
              (date.getMonth() === minDate.getMonth() && date.getDate() < minDate.getDate())
            ) {
              this.searchTableData[i].disabled = true
            }
          }
        }
      }

      this.$common.logData(this.searchTableSelectedRow, 'searchTableSelectedRow')
      this.$common.logData(this.searchTableData, 'After add package course')
    },

    async handlePackageLessonConfirm() {
      if (this.searchTableSelectedRow.length < 1) {
        return
      }

      this.tableLoading = true
      this.$store.dispatch('setLoading', true)
      try {
        const minDate = new Date(this.searchTableSelectedRow[0].date)
        minDate.setDate(minDate.getDate() - 1)

        let payload = {
          student_id: this.studentData.student_id,
          date: this.$formatter.formatDate(minDate),
          course_codes: this.searchTableSelectedRow.map(el => el.course_code),
        }
        const data = await this.$Fetcher.GetPackageLessonsPreview(payload)

        // --- 未問過要唔要用剩余堂數
        if (
          this.tempSelectedPackage.max_lesson_count === this.tempSelectedPackage.lesson_count &&
          this.remainPackageLessonCount > 0
        ) {
          this.allApplyCount = this.tempSelectedPackage.lesson_count + this.remainPackageLessonCount
          const confirmCombine = await this.$refs['combineRemainDialog'].show()
          if (confirmCombine) {
            this.tempSelectedPackage.max_lesson_count =
              this.tempSelectedPackage.lesson_count + this.remainPackageLessonCount
          }
        }

        if (!this.tempSelectedPackage.selected_lessons) {
          this.tempSelectedPackage.selected_lessons = []
        }
        const current_selected_lessons_count = this.tempSelectedPackage.selected_lessons.length
        if (this.tempSelectedPackage.max_lesson_count <= current_selected_lessons_count) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: 'message.lessonCountNotEnough',
            type: 'error',
            refresh: false,
            redirect: '',
          })
          this.$refs.unpaidLessonFormDialog.setLoading(false)
        } else {
          let relatedLesson = data.slice(0, this.tempSelectedPackage.max_lesson_count - current_selected_lessons_count)
          this.$common.logData(relatedLesson, '相關課堂')

          this.tempSelectedPackage.selected_lessons = [...this.tempSelectedPackage.selected_lessons, ...relatedLesson]
          if (relatedLesson.length < this.tempSelectedPackage.max_lesson_count - current_selected_lessons_count) {
            this.createLessonCount =
              this.tempSelectedPackage.max_lesson_count - current_selected_lessons_count - relatedLesson.length
            this.$refs['addLessonFormDialog'].show()
          } else {
            this.addNewPackage()
          }
        }
      } catch (err) {
        this.$common.error(err)
      } finally {
        this.tableLoading = false
        this.$store.dispatch('setLoading', false)
        this.addCourseDialogOpen = false
      }
    },

    addNewPackage() {
      this.packageTableData = this.packageTableData.filter(item => item.order_id !== -1)
      let newPackage = this.parsePackageTableData(this.tempSelectedPackage)
      this.packageTableData.push(newPackage)
      this.packageSelectedRow.push({ id: newPackage.id })
      this.$common.logData(this.packageTableData, 'packageTableData')
    },

    // ========= 付款 =========
    async handlePaymentSubmit() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'processing',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      this.$store.dispatch('setLoading', true)

      if (
        (this.currentTabType === 'course' && !this.$validate.DataValid(this.courseSelectedRow)) ||
        (this.currentTabType === 'product' && !this.$validate.DataValid(this.productSelectedRow)) ||
        (this.currentTabType === 'package' && !this.$validate.DataValid(this.packageSelectedRow))
      ) {
        let errorMsg = ''
        if (this.currentTabType === 'product') {
          errorMsg = 'message.atLeastOneProduct'
        } else if (this.currentTabType === 'package') {
          errorMsg = 'message.atLeastOnePackage'
        } else {
          errorMsg = 'message.atLeastOneCourse'
        }

        this.setDialogMessage({
          title: 'message.operationalError',
          message: errorMsg,
          isError: true,
          returnLink: null,
        })
        this.setShowDialog(true)
        this.$store.dispatch('setLoading', false)
        return
      }

      let updateItems = [] // { order_id: -1, items: [] }
      let newItems = []
      let nextMonthItems = []

      if (this.currentTabType === 'course') {
        this.courseSelectedRow.forEach(item => {
          const found = this.courseTableData.find(el => el.id === item.id)
          if (found) {
            if (found.order_id === -2) {
              nextMonthItems.push(found)
            } else if (found.order_id === -1) {
              newItems.push(found)
            } else if (found.order_id > -1) {
              const temp = updateItems.find(el => el.order_id === found.order_id)
              if (temp) {
                temp.items.push(found)
              } else {
                updateItems.push({
                  order_id: found.order_id,
                  items: [found],
                })
              }
            }
          }
        })
      } else if (this.currentTabType === 'package') {
        this.packageSelectedRow.forEach(item => {
          const found = this.packageTableData.find(el => el.id === item.id)
          if (found) {
            if (found.order_id === -1) {
              newItems.push(found)
            } else if (found.order_id > -1) {
              const temp = updateItems.find(el => el.order_id === found.order_id)
              if (temp) {
                temp.items.push(found)
              } else {
                updateItems.push({
                  order_id: found.order_id,
                  items: [found],
                })
              }
            }
          }
        })

        if (newItems.length > 1) {
          this.setDialogMessage({
            title: 'message.operationalError',
            message: 'message.onceBuyOnePackage',
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
          this.$store.dispatch('setLoading', false)
          return
        }
      } else if (this.currentTabType === 'product') {
        this.productSelectedRow.forEach(item => {
          const found = this.productTableData.find(el => el.id === item.id)
          if (found) {
            if (found.order_id === -1) {
              newItems.push(found)
            } else if (found.order_id > -1) {
              const temp = updateItems.find(el => el.order_id === found.order_id)
              if (temp) {
                temp.items.push(found)
              } else {
                updateItems.push({
                  order_id: found.order_id,
                  items: [found],
                })
              }
            }
          }
        })
      }

      if (updateItems.length > 0 && (newItems.length > 0 || nextMonthItems.length > 0)) {
        this.setDialogMessage({
          title: 'message.operationalError',
          message: 'message.onceNewOrdEditOrder',
          isError: true,
          returnLink: null,
        })
        this.setShowDialog(true)
        this.$store.dispatch('setLoading', false)
        return
      }

      if (updateItems.length > 1) {
        this.setDialogMessage({
          title: 'message.operationalError',
          message: 'message.onceUpdateOneOrder',
          isError: true,
          returnLink: null,
        })
        this.setShowDialog(true)
        this.$store.dispatch('setLoading', false)
        return
      }

      if (updateItems.length) {
        this.updateOrder(updateItems[0].order_id)
      } else {
        try {
          if (nextMonthItems.length) {
            await this.genNextMonthOrder(nextMonthItems)
          }

          if (newItems.length) {
            await this.makeNewOrder(newItems)
          }

          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: 'message.paymentSuccess',
            type: 'success',
            refresh: false,
            redirect: 'PayTuitionList',
          })
        } catch (err) {
          this.setDialogMessage({
            title: 'message.paymentFail',
            message: err,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      }
    },

    async updateOrder(orderId) {
      const payload = {
        remark: this.remark,
        payment_method: this.selectedPaymentMethod,
        payment_image: this.paymentImages.length ? this.paymentImages[0].file : '',
        status: this.selectedStatus,
      }
      try {
        await this.$Fetcher.UpdateOrder(orderId, payload)
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'message.updateSuccess',
          type: 'success',
          refresh: true,
          redirect: '',
        })
      } catch (err) {
        this.$common.error(err)
        this.setDialogMessage({
          title: 'message.updateFail',
          message: err,
          isError: true,
          returnLink: null,
        })
        this.setShowDialog(true)
      } finally {
        this.$store.dispatch('setLoading', false)
      }
    },

    genNextMonthOrder(items) {
      return new Promise(async (resolve, reject) => {
        const payload = {
          student_id: this.studentData.student_id,
          except_lesson_id_list: [],
          lesson_id_list: [],
        }

        this.nextPreviewOrder.item_list.forEach(item => {
          let found = null
          for (let i = 0; i < items.length; i++) {
            if (items[i].selected_lesson_id_list.includes(item.id)) {
              found = items[i]
              break
            }
          }

          if (found !== null) {
            payload.lesson_id_list.push({
              id: item.id,
              discount_type: found.discount_type ? found.discount_type : '',
              discount_value: found.discount_value ? found.discount_value : 0,
            })
          } else {
            payload.except_lesson_id_list.push(item.id)
          }
        })

        try {
          const data = await this.$Fetcher.GenNextMonthOrder(payload)
          await this.updateNextOrderDetails(data.id)

          const selecteds = []
          for (let i = 0; i < this.courseSelectedRow.length; i++) {
            const found = this.courseTableData.find(el => el.id === this.courseSelectedRow[i].id)
            if (found && found.order_id !== -2) {
              selecteds.push(this.courseSelectedRow[i])
            }
          }
          this.courseSelectedRow = selecteds
          this.courseTableData = this.courseTableData.filter(item => item.order_id !== -2)
          resolve(true)
        } catch (err) {
          this.$common.error(err)
          reject(`[INVOICE ERROR] ${err}`)
        }
      })
    },
    updateNextOrderDetails(orderId) {
      return new Promise(async (resolve, reject) => {
        try {
          const payload = {
            remark: this.remark,
            payment_method: this.selectedPaymentMethod,
            payment_image: this.paymentImages.length ? this.paymentImages[0].file : '',
            status: this.selectedStatus,
          }

          await this.$Fetcher.UpdateOrder(orderId, payload)
          resolve(true)
        } catch (err) {
          this.$common.error(err)
          reject(`[UPDATE] ${err}`)
        }
      })
    },

    makeNewOrder(items) {
      return new Promise(async (resolve, reject) => {
        let payload = {
          student_id: this.studentData.student_id,
          school_id: this.studentData.school_id,
          remark: this.remark,
          payment_method: this.selectedPaymentMethod,
          payment_image: this.paymentImages.length ? this.paymentImages[0].file : '',
          lesson_id_list: [],
          product_id_list: [],
          except_lesson_id_list: [],
          subtotal: this.totalAmount,
          status: this.selectedStatus,
          discount_type: '',
          discount_value: 0,
        }

        if (this.currentTabType === 'course') {
          items.forEach(targetRow => {
            targetRow.lesson_data_list.forEach(lesson => {
              if (targetRow.selected_lesson_id_list.includes(lesson.id)) {
                let discount_value = null
                if (
                  this.$validate.DataValid(targetRow.discount_type) &&
                  this.$validate.DataValid(targetRow.discount_value)
                ) {
                  if (targetRow.discount_type === 'percentage') {
                    discount_value = 100 - targetRow.discount_value
                  } else if (targetRow.discount_type === 'fix_amount') {
                    discount_value = targetRow.discount_value
                  }
                }

                payload['lesson_id_list'].push({
                  id: lesson.id,
                  discount_type:
                    this.$validate.DataValid(targetRow.discount_type) && this.$validate.DataValid(discount_value)
                      ? targetRow.discount_type
                      : '',
                  discount_value:
                    this.$validate.DataValid(targetRow.discount_type) && this.$validate.DataValid(discount_value)
                      ? discount_value
                      : 0,
                })
              } else {
                payload['except_lesson_id_list'].push(lesson.id)
              }
            })
          })

          this.$common.logData(payload.lesson_id_list, '購買課堂')
        } else if (this.currentTabType === 'product') {
          items.forEach(targetRow => {
            let discount_value = null
            if (
              this.$validate.DataValid(targetRow.discount_type) &&
              this.$validate.DataValid(targetRow.discount_value)
            ) {
              if (targetRow.discount_type === 'percentage') {
                discount_value = 100 - targetRow.discount_value
              } else if (targetRow.discount_type === 'fix_amount') {
                discount_value = targetRow.discount_value
              }
            }

            payload['product_id_list'].push({
              id: targetRow.product_id,
              amount: targetRow.qty,
              discount_type:
                this.$validate.DataValid(targetRow.discount_type) && this.$validate.DataValid(discount_value)
                  ? targetRow.discount_type
                  : '',
              discount_value:
                this.$validate.DataValid(targetRow.discount_type) && this.$validate.DataValid(discount_value)
                  ? discount_value
                  : 0,
            })
          })

          this.$common.logData(payload.product_id_list, '購買商品')
        } else if (this.currentTabType === 'package') {
          const targetRow = items[0]

          payload['package_id'] = targetRow.package_id
          payload['use_remain'] = targetRow.max_lesson_count > targetRow.lesson_count ? true : false
          payload['unpaid_lesson_id_list'] = []
          targetRow.lesson_data_list.forEach(lesson => {
            if (lesson.student_ids.includes(this.studentData.student_id)) {
              payload['unpaid_lesson_id_list'].push(lesson.id)
            } else {
              const found = payload['lesson_id_list'].find(el => el.course_code === lesson.course_code);
              if (!found) {
                payload['lesson_id_list'].push({
                  id: lesson.id,
                  discount_type: '',
                  discount_value: 0,
                  course_code: lesson.course_code
                })
              }
            }
          })

          payload['lesson_id_list'].forEach(item => {
            delete item['course_code'];
          })
        }

        if (this.$validate.DataValid(this.orderDiscountType) && this.$validate.DataValid(this.orderDiscountValue)) {
          payload['discount_type'] = this.orderDiscountType

          let discount_value = 0
          if (this.orderDiscountType === 'percentage') {
            discount_value = 100 - parseFloat(this.orderDiscountValue)
          } else if (this.orderDiscountType === 'fix_amount') {
            discount_value = parseFloat(this.orderDiscountValue)
          }
          payload['discount_value'] = discount_value
        }

        if (this.selectedStatus === 'paid') {
          const today = new Date()
          payload['pay_date'] = `${this.$formatter.formatDate(today, true)}`
        }

        this.$common.logData(payload, 'makeNewOrder')

        try {
          await this.$Fetcher.NewOrder(payload)
          resolve(true)
        } catch (err) {
          this.$common.error(err)
          reject(`[NEW] ${err}`)
        }
      })
    },

    async handleSiteLoaded() {
      console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>> handleSiteLoaded: PayTuitionPayment')
      if (this.$validate.DataValid(this.id)) {
        this.getPayTuitionData()
      } else {
        this.setDialogMessage({
          message: 'noData',
          returnLink: { name: 'PayTuitionList' },
        })
        this.setShowDialog(true)
        this.$store.dispatch('toggleLoadingPage', false)
      }
    },
  },

  destroyed() {
    window.removeEventListener('onSiteLoaded', this.handleSiteLoaded)
  },
  mounted() {
    this.$store.dispatch('toggleLoadingPage', true)
    window.addEventListener('onSiteLoaded', this.handleSiteLoaded)
  },

  // ------ navigation guard ------
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters.isLoading) {
      this.$store.dispatch('toggleAlertMessage', {
        show: true,
        message: 'processing',
        type: 'error',
        refresh: false,
        redirect: '',
      })
      next(false)
    } else {
      next()
    }
  },
}
</script>

<style lang="scss" scoped>
.order-card-title {
  background-color: #e2e2e2;
}

.details-row {
  justify-content: space-between;
  & * {
    color: var(--v-colorBlack-base);
    font-size: 0.875rem;
    font-weight: 500;
  }
}
</style>
